export const OK_HTTP_CODE = 200;
export const CREATED_HTTP_CODE = 201;
export const NO_CONTENT_HTTP_CODE = 204;
export const REDIRECT_HTTP_CODE = 301;
export const BAD_REQUEST_HTTP_CODE = 400;
export const FORBIDDEN_HTTP_CODE = 403;
export const NOT_FOUND_HTTP_CODE = 404;
export const REQUEST_TIMEOUT_HTTP_CODE = 408;
export const CONFLICT_HTTP_CODE = 409;
export const GONE_HTTP_CODE = 410;
export const MISDIRECTED_REQUEST_HTTP_CODE = 421;
export const UNAUTHORIZED_CODE = 401;
