import { GIFT_CARD_CODE } from '@configs/checkout-payment-methods';

import { PAYMENT_TYPES } from '@types/PaymentTypes';

export const GIFT_CARD_METHOD_MOCK = {
    additionalInfo: null,
    code: GIFT_CARD_CODE,
    description: 'Karta podarunkowa',
    disallowedForCompany: true,
    disallowedForPrivatePerson: false,
    disallowedForGiftCard: true,
    id: '376970d1-4112-4af3-9c81-1c76053da10b',
    title: 'Karta podarunkowa',
    type: PAYMENT_TYPES.ONLINE,
};
