import globalService from './modules/global';
import categoryService from './modules/category';
import cmsService from './modules/cms';
import productService from './modules/product';
import cartService from './modules/cart';
import customerService from './modules/customer';
import searchService from './modules/search';
import checkoutService from './modules/checkout';
import gdprService from './modules/gdpr';
import attributesService from './modules/attributes';
import recommendationsService from './modules/recommendations';
import orderService from './modules/order';
import ordersService from './modules/orders';
import packageTrackingService from './modules/package-tracking';
import contactService from './modules/contact';
import validateService from './modules/validate';
import modivoClubService from './modules/modivo-club';
import giftCardService from './modules/gift-card';
import consentsService from './modules/consents';
import authService from './modules/auth';
import avatarService from './modules/avatar';
import esizemeService from './modules/esizeme';
import adTechService from './modules/adtech';
import pocSearchService from './modules/search-service';
import paymentsService from './modules/payments';

export default (
    $errorHandler,
    megatronClient,
    httpClient,
    $performanceRecorder,
    helpers,
    normalizeMegatronResponse
) => ({
    global: globalService(megatronClient),
    category: categoryService({
        megatronClient,
        httpClient,
        normalizeMegatronResponse,
        $errorHandler,
        $performanceRecorder,
        helpers,
    }),
    cms: cmsService(megatronClient),
    product: productService({
        megatronClient,
        httpClient,
        normalizeMegatronResponse,
        $errorHandler,
        helpers,
    }),
    cart: cartService({
        megatronClient,
        httpClient,
        $errorHandler,
        normalizeMegatronResponse,
        helpers,
    }),
    customer: customerService({ megatronClient, $errorHandler, helpers }),
    search: searchService({
        megatronClient,
        httpClient,
        normalizeMegatronResponse,
        $errorHandler,
        helpers,
    }),
    checkout: checkoutService({ megatronClient, $errorHandler }),
    gdpr: gdprService({ megatronClient, $errorHandler, helpers }),
    order: orderService({ megatronClient, $errorHandler }),
    orders: ordersService({ megatronClient, $errorHandler }),
    packageTracking: packageTrackingService({ megatronClient, $errorHandler }),
    contact: contactService(megatronClient, $errorHandler),
    validate: validateService(megatronClient),
    attributes: attributesService({
        megatronClient,
        httpClient,
        $errorHandler,
        helpers,
    }),
    recommendations: recommendationsService({
        megatronClient,
        helpers,
        $errorHandler,
    }),
    modivoClub: modivoClubService(),
    giftCard: giftCardService(),
    consents: consentsService(megatronClient),
    auth: authService(httpClient),
    avatar: avatarService(httpClient),
    esizeme: esizemeService(megatronClient, $errorHandler),
    adTech: adTechService({
        httpClient,
        helpers,
        $errorHandler,
    }),
    searchService: pocSearchService({ httpClient, $errorHandler, helpers }),
    payments: paymentsService({ httpClient, megatronClient, $errorHandler }),
});
